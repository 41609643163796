import { useCurrencyStore } from "~/store/currency";
import { CurrencyCode, OfferCode, OfferType } from "~/types";

const EVENT_CATEGORIES = {
  SCHEDULE: "schedule",
  PURCHASE: "purchase",
  MEETING: "meeting",
  TOOLBAR: "toolbar",
};

const EVENT = {
  HOST_FREE_MEETING: "host_free_meeting",
  HOST_PAID_MEETING: "host_paid_meeting",
  FREE_EVENT_SCHEDULE: "free_event_schedule",
  PAID_EVENT_SCHEDULE: "paid_event_schedule",
  AUDIO_ROOM_PURCHASE: "audio_room_purchase",
  LARGE_AUDIO_ROOM_PURCHASE: "large_audio_room_purchase",
  VIDEO_ROOM_PURCHASE: "video_room_purchase",
  LARGE_VIDEO_ROOM_PURCHASE: "large_video_room_purchase",
  PURCHASE_INITIATION: "purchase_initiation",
  SCHEDULE_INITIATION: "schedule_initiation",
  MOBILE_TOOLBAR_PARTICIPANTS_CLICK: "mobile_toolbar_participants_click",
  MOBILE_TOOLBAR_MORE_CLICK: "mobile_toolbar_more_click",
  MOBILE_TOOLBAR_REACTION_CLICK: "mobile_toolbar_reaction_click",
};

type PurchaseArgs = {
  currency: CurrencyCode;
  price: number;
  offerCode: OfferCode;
};

type ScheduleArgs = {
  duration: number;
  repeatability: string;
  invitedCount: number;
};

export const useAnalytics = () => {
  const gtm = useGtm();
  const runtimeConfig = useRuntimeConfig();
  const currencyStore = useCurrencyStore();

  const scheduleInitiation = () => {
    gtm?.trackEvent({
      category: EVENT_CATEGORIES.SCHEDULE,
      event: EVENT.SCHEDULE_INITIATION,
      action: EVENT.SCHEDULE_INITIATION,
    });
  };

  const freeEventSchedule = ({
    duration,
    invitedCount,
    repeatability,
  }: ScheduleArgs) => {
    gtm?.trackEvent({
      category: EVENT_CATEGORIES.SCHEDULE,
      event: EVENT.FREE_EVENT_SCHEDULE,
      action: EVENT.FREE_EVENT_SCHEDULE,
      duration,
      repeatability,
      invited_count: invitedCount,
    });
  };

  const paidEventSchedule = ({
    duration,
    invitedCount,
    repeatability,
  }: ScheduleArgs) => {
    gtm?.trackEvent({
      category: EVENT_CATEGORIES.SCHEDULE,
      event: EVENT.PAID_EVENT_SCHEDULE,
      action: EVENT.PAID_EVENT_SCHEDULE,
      duration,
      repeatability,
      invited_count: invitedCount,
    });
  };

  const eventSchedule = ({
    duration,
    invitedCount,
    repeatability,
    maxDuration,
  }: ScheduleArgs & { maxDuration: number }) => {
    if (maxDuration < 3600) {
      return freeEventSchedule({ duration, invitedCount, repeatability });
    }
    return paidEventSchedule({ duration, invitedCount, repeatability });
  };

  const purchaseInitiation = ({ offerCode, currency, price }: PurchaseArgs) => {
    gtm?.trackEvent({
      category: EVENT_CATEGORIES.PURCHASE,
      event: EVENT.PURCHASE_INITIATION,
      action: EVENT.PURCHASE_INITIATION,
      offer_code: offerCode,
      currency,
      value: price,
    });
  };

  const audioRoomPurchase = ({ currency, price, offerCode }: PurchaseArgs) => {
    gtm?.trackEvent({
      category: EVENT_CATEGORIES.PURCHASE,
      event: EVENT.AUDIO_ROOM_PURCHASE,
      action: EVENT.AUDIO_ROOM_PURCHASE,
      value: price,
      currency,
      offer_code: offerCode,
    });
  };

  const largeAudioRoomPurchase = ({
    currency,
    price,
    offerCode,
  }: PurchaseArgs) => {
    gtm?.trackEvent({
      category: EVENT_CATEGORIES.PURCHASE,
      event: EVENT.LARGE_AUDIO_ROOM_PURCHASE,
      action: EVENT.LARGE_AUDIO_ROOM_PURCHASE,
      value: price,
      currency,
      offer_code: offerCode,
    });
  };

  const videoRoomPurchase = ({ currency, price, offerCode }: PurchaseArgs) => {
    gtm?.trackEvent({
      category: EVENT_CATEGORIES.PURCHASE,
      event: EVENT.VIDEO_ROOM_PURCHASE,
      action: EVENT.VIDEO_ROOM_PURCHASE,
      value: price,
      currency,
      offer_code: offerCode,
    });
  };

  const largeVideoRoomPurchase = ({
    currency,
    price,
    offerCode,
  }: PurchaseArgs) => {
    gtm?.trackEvent({
      category: EVENT_CATEGORIES.PURCHASE,
      event: EVENT.LARGE_VIDEO_ROOM_PURCHASE,
      action: EVENT.LARGE_VIDEO_ROOM_PURCHASE,
      value: price,
      currency,
      offer_code: offerCode,
    });
  };

  const roomPurchase = async ({ roomId }: { roomId: string }) => {
    const offerData = await useAuthRequest<OfferType>(
      `${runtimeConfig.public.backendApiUrl}/rooms/${roomId}/offers`,
    );
    if (offerData.data.value) {
      const offerCode = offerData.data.value.offer_code;
      const currency = currencyStore.defaultCurrency.currency.code;
      const price =
        offerData.data.value.offers.find((item) => item.currency === currency)
          ?.price || 0;

      switch (offerCode) {
        case "AUDIO_ROOM":
          return audioRoomPurchase({ currency, price, offerCode });
        case "BIG_AUDIO_ROOM":
          return largeAudioRoomPurchase({ currency, price, offerCode });
        case "VIDEO_ROOM":
          return videoRoomPurchase({ currency, price, offerCode });
        case "BIG_VIDEO_ROOM":
          return largeVideoRoomPurchase({ currency, price, offerCode });
        default:
          break;
      }
    }
  };

  const hostMeeting = ({ maxDuration }: { maxDuration: number }) => {
    if (maxDuration < 3600) {
      return gtm?.trackEvent({
        category: EVENT_CATEGORIES.MEETING,
        event: EVENT.HOST_FREE_MEETING,
        action: EVENT.HOST_FREE_MEETING,
      });
    }
    return gtm?.trackEvent({
      category: EVENT_CATEGORIES.MEETING,
      event: EVENT.HOST_PAID_MEETING,
      action: EVENT.HOST_PAID_MEETING,
    });
  };

  const mobileToolbarParticipantsClick = () => {
    return gtm?.trackEvent({
      category: EVENT_CATEGORIES.TOOLBAR,
      event: EVENT.MOBILE_TOOLBAR_PARTICIPANTS_CLICK,
      action: EVENT.MOBILE_TOOLBAR_PARTICIPANTS_CLICK,
    });
  };

  const mobileToolbarMoreClick = () => {
    return gtm?.trackEvent({
      category: EVENT_CATEGORIES.TOOLBAR,
      event: EVENT.MOBILE_TOOLBAR_MORE_CLICK,
      action: EVENT.MOBILE_TOOLBAR_MORE_CLICK,
    });
  };

  const mobileToolbarReactionClick = () => {
    return gtm?.trackEvent({
      category: EVENT_CATEGORIES.TOOLBAR,
      event: EVENT.MOBILE_TOOLBAR_REACTION_CLICK,
      action: EVENT.MOBILE_TOOLBAR_REACTION_CLICK,
    });
  };

  return {
    scheduleInitiation,
    eventSchedule,
    purchaseInitiation,
    roomPurchase,
    hostMeeting,
    mobileToolbarParticipantsClick,
    mobileToolbarMoreClick,
    mobileToolbarReactionClick,
  };
};
